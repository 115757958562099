<template>
  <div class="mb-20 grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
    <div
      v-if="highlightedProduct"
      :ref="
        setupHPCImpressionTracker(
          productToTrackingData(highlightedProduct, {
            provider: 'algolia',
            list: 'landing page highlighted product',
          }),
        )
      "
      class="md:col-span-2"
    >
      <HighlightedProductCard
        class="h-full"
        :color-swatches="highlightedProduct.specifications?.color ?? []"
        :description="highlightedProduct.description"
        :image-alt="highlightedProduct.title"
        :image-src="highlightedProduct.image"
        :price="highlightedProduct.price"
        :price-new="highlightedProduct.priceNew"
        :review-rating="highlightedProduct.reviewRating"
        :specifications="
          Object.entries(highlightedProduct.specifications ?? {})
            .map(([key, values]) => ({
              label: key,
              values: values.map(
                ({ name, value }) => name + (value ? ` (${value})` : ''),
              ),
            }))
            .filter(({ label }) => label !== 'color')
        "
        :title="highlightedProduct.title"
        :url="highlightedProduct.productPageLink.href ?? '#'"
        :warranty="highlightedProduct.warrantyDuration"
        @click="
          trackProductClick(highlightedProduct, {
            provider: 'algolia',
            list: 'landing page highlighted product',
            position: 1,
            source: 'search',
            queryID: querySearchInfos?.id,
            index: querySearchInfos?.index,
          })
        "
      />
    </div>
    <ProductCard
      v-for="(product, index) in products"
      :key="product.listingId"
      :color-swatches="product.specifications?.color ?? []"
      :description="product.description"
      :image-alt="product.title"
      :image-src="product.image"
      :listing-id="product.listingId"
      :price="product.price"
      :price-new="product.priceNew"
      :review-rating="product.reviewRating"
      :specifications="
        Object.entries(product.specifications ?? {})
          .map(([key, values]) => ({
            label: key,
            values: values.map(
              ({ name, value }) => name + (value ? ` (${value})` : ''),
            ),
          }))
          .filter(({ label }) => label !== 'color')
      "
      :tags="index < 3 ? [i18n(translations.recommendationTag)] : []"
      :title="product.title"
      :url="product.productPageLink"
      :warranty="product.warrantyDuration"
      @click="
        trackProductClick(product, {
          provider: 'algolia',
          list: 'landing page product',
          position: highlightedProduct ? index + 2 : index + 1,
          source: 'search',
          queryID: querySearchInfos?.id,
          index: querySearchInfos?.index,
        })
      "
    />
    <div
      v-if="marketingContent"
      :ref="
        setupBannerImpressionTracker({
          name: 'landing_page',
          creative: marketingContent.alt,
          position: 'landing_page_3',
        })
      "
      class="md:col-span-2 lg:col-span-3"
      :class="{
        'row-start-4  md:row-start-4  lg:row-start-2': products.length >= 2,
      }"
    >
      <MarketingCard
        :alt="marketingContent.alt"
        :srcLarge="marketingContent.srcLarge"
        :srcSmall="marketingContent.srcSmall"
        :url="marketingContent.url"
        @click="
          trackLandingBannerClick({
            name: 'landing_page',
            creative: marketingContent.alt,
            position: 'landing_page_3',
          })
        "
      />
    </div>
    <div
      v-if="article"
      class="md:col-span-2 lg:col-span-1"
      :class="{
        'row-start-10 md:row-start-8 lg:col-start-3 lg:row-start-4':
          products.length >= 7,
      }"
    >
      <ArticleCard
        :id="article.id"
        :category="article.category"
        class="md:w-full"
        :imageAlt="article.imageAlt"
        :imageSrc="article.imageSrc"
        :link="article.link"
        :text="article.text"
        :title="article.title"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useState } from '#imports'

import ProductCard from '@backmarket/nuxt-layer-recommendation/ProductCard.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import {
  productToTrackingData,
  useClickTracking,
} from '@backmarket/nuxt-layer-recommendation/useProductTracking'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import ArticleCard, {
  type ArticleCard as ArticleCardType,
} from '~/scopes/article/components/ArticleCard/ArticleCard.vue'

import type { SearchQuery } from '../../search/composables/useProductsSearch'
import { useImpressionTracker } from '../composables/useImpressionTracker'

import HighlightedProductCard from './HighlightedProductCard.vue'
import MarketingCard from './MarketingCard.vue'
import translations from './ResultGrid.translations'

const { trackProductClick } = useClickTracking()

const {
  trackLandingBannerImpression,
  trackLandingBannerClick,
  trackProductImpression,
} = useTracking()
const { setupImpressionTracker: setupBannerImpressionTracker } =
  useImpressionTracker(trackLandingBannerImpression)
const { setupImpressionTracker: setupHPCImpressionTracker } =
  useImpressionTracker(trackProductImpression)

const querySearchInfos = useState<SearchQuery>('search-query')

const i18n = useI18n()

defineProps<{
  highlightedProduct?: Product
  products: Product[]
  marketingContent?: {
    alt: string
    srcSmall: string
    srcLarge: string
    url: string
  }
  article?: ArticleCardType
}>()
</script>
