import { useState } from '#imports'
import { computed, ref, shallowReactive, watch } from 'vue'

import { createInMemoryCache } from '@algolia/cache-in-memory'
import type {
  AlgoliaProduct,
  SearchConfiguration,
} from '@backmarket/http-api/src/api-specs-search-reco/search'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import algoliasearch from 'algoliasearch'

import {
  algoliaHitToProduct,
  algoliaHitToVariant,
  filtersToWhereClause,
  getGraphFacet,
  hasValues,
  transformFacetsValues,
} from '../algolia/algoliaFunctions'

interface Value {
  label: string
  name: string
  position: number
  count: number
}

export interface Facet {
  title: string
  name: string
  type: 'checkbox' | 'radio'
  values: Value[]
}

export interface UiPriceFacet {
  avg: number
  max: number
  name: string
  step: number
  title: string
  values: number[]
}

export interface SearchQuery {
  id?: string
  index?: string
}

const DEBOUNCE_DELAY = 500

export async function useProductsSearch(
  appId: string,
  configuration: SearchConfiguration,
  apiKey: string,
  initialQueryValue = '',
) {
  const logger = useLogger()

  const products = ref<Product[]>([])
  const total = ref(0)
  const facets = ref<Facet[]>([])
  const sortInput = ref(configuration.indexes.active.name)
  const pageInput = ref(0)
  const pageCount = ref(0)
  const priceInput = ref<[number, number]>([0, 0])
  const priceFacet = ref<UiPriceFacet>()
  const query = ref(initialQueryValue)

  const index = computed(() => {
    return (
      [
        configuration.indexes.active,
        ...(configuration.indexes?.other ?? []),
      ].find(({ name }) => name === sortInput.value) ??
      configuration.indexes.active
    )
  })

  const querySearchInfos = useState<SearchQuery>('search-query', () => ({
    id: '',
    index: '',
  }))

  const filtersInput = shallowReactive(
    configuration.facets.reduce<Record<string, string[]>>((acc, item) => {
      return {
        ...acc,
        [item.name]: [],
      }
    }, {}),
  )

  const facetCount = computed(() => {
    const priceFiltered =
      priceInput.value[0] > 0 || priceInput.value[1] !== priceFacet.value?.max
    const numberOfFiltersActive = Object.values(filtersInput).filter(
      (filterValue) => filterValue.length > 0,
    ).length

    return numberOfFiltersActive + (priceFiltered ? 1 : 0)
  })

  const algoliaClient = algoliasearch(appId, apiKey, {
    responsesCache: createInMemoryCache(),
    requestsCache: createInMemoryCache({ serializable: false }),
  })

  async function search() {
    const defaultFacets = ['price', 'page', 'q', 'sort']
    const sidebarFacets = configuration.facets.map(({ name }) => name)
    const priceFacets =
      configuration.priceFacet?.scales.map((facet) => facet.facetName) ?? []
    const requestOptions = {
      ...index.value.queryParams,
      filters: filtersToWhereClause(filtersInput, configuration.complexFilter),
      facets: [...defaultFacets, ...sidebarFacets, ...priceFacets],
      numericFilters:
        priceInput.value[1] > 0
          ? [`price>=${priceInput.value[0]}`, `price<=${priceInput.value[1]}`]
          : undefined,
      page: pageInput.value,
      hitsPerPage: 30,
    }

    const algoliaIndex = algoliaClient.initIndex(index.value.name)

    try {
      const {
        nbHits,
        facets_stats: algoliaFacetsStats,
        hits,
        queryID,
        nbPages,
        facets: algoliaFacets,
        processingTimeMS,
      } = await algoliaIndex.search<AlgoliaProduct>(query.value, requestOptions)

      const algoliaPriceValues = {
        avg: algoliaFacetsStats?.price.avg ?? 0,
        max: algoliaFacetsStats?.price.max ?? 0,
      }

      querySearchInfos.value = {
        id: queryID,
        index: index.value.name,
      }

      total.value = nbHits
      if (configuration.productGroupingType === 'parent') {
        products.value = hits.map((hit) => algoliaHitToProduct(hit))
      } else if (configuration.productGroupingType === 'variant') {
        products.value = hits.map((hit) => algoliaHitToVariant(hit))
      }
      pageCount.value = nbPages
      facets.value = configuration.facets.map((conf) => {
        const isFilteredFacet = hasValues(conf.name, filtersInput)
        const values = transformFacetsValues(
          algoliaFacets?.[conf.name],
          conf.isSortedByBusiness,
        )

        return {
          title: conf.title,
          name: conf.name,
          type: conf.type,
          values: isFilteredFacet
            ? facets.value.find((facet) => facet.name === conf.name)?.values ??
              values
            : values,
        }
      })
      if (!priceFacet.value) {
        priceFacet.value = getGraphFacet(
          configuration.priceFacet,
          algoliaFacets,
          algoliaPriceValues,
        )

        priceInput.value = [0, priceFacet.value?.max ?? 0]
      }
      logger.info('[S&R] PLP search query success', {
        endpointName: 'algoliaSearch',
        type: 'API_REQUEST_SUCCESS',
        searchProcessingTime: processingTimeMS,
      })
    } catch (e) {
      logger.error('[S&R] PLP search query failed', {
        endpointName: 'algoliaSearch',
        type: 'API_REQUEST_FAIL',
        e,
      })
    }
  }

  await search()

  watch(
    [() => ({ ...filtersInput }), sortInput, pageInput, query],
    () => {
      void search()
    },
    { deep: true },
  )

  const debouncedSearch = useDebounceFn(search, DEBOUNCE_DELAY)

  watch(priceInput, debouncedSearch)

  return {
    total,
    pageCount,
    products,
    facets,
    priceFacet,
    facetCount,
    filtersInput,
    sortInput,
    pageInput,
    priceInput,
    query,
  }
}
